import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { loadDefaultPerPage } from 'utils/paginate';

type FilterValue = string | undefined;

export type PaginatedListParams<FilterKey extends string = string> = {
  page: number;
  perPage: number;
  search: string;
  sortBy?: string | undefined;
  sortDirection?: 'asc' | 'desc' | undefined;
  filters: Partial<Record<FilterKey, FilterValue>>;
};

export type PaginatedListConfig<FilterKey extends string = string> = {
  // Page size options
  readonly perPageOptions: readonly [number, ...number[]];
  // Key used for search query - used if the API uses something other than 'search' for search
  readonly searchKey: string;
  // Available filter keys
  readonly filterKeys: FilterKey[];
  // Prefix used to namespace URL state in case of multiple paginated lists on the same page
  readonly urlStatePrefix?: string;
};

export type PaginatedListState<T extends string = any> = ReturnType<
  typeof usePaginatedListState<T>
>;

export const usePaginatedListState = <FilterKey extends string>(
  initialConfig: Partial<PaginatedListConfig<FilterKey>> = {},
  initialParams: Partial<PaginatedListParams<FilterKey>> = {}
) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const config: PaginatedListConfig<FilterKey> = useMemo(
    () => ({
      perPageOptions: [10, 20, 50],
      searchKey: 'search',
      urlStatePrefix: undefined,
      filterKeys: [],
      ...initialConfig,
    }),
    []
  );

  const defaultParams: PaginatedListParams = useMemo(
    () => ({
      page: 1,
      perPage: loadDefaultPerPage(config.perPageOptions),
      search: '',
      filters: {},
    }),
    [config]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  // Params are derived from URL search string
  const params = useMemo<PaginatedListParams<FilterKey>>(() => {
    const currentParams = isInitialized
      ? parseParams(searchParams.toString(), config)
      : initialParams;

    return { ...defaultParams, ...currentParams };
  }, [searchParams, isInitialized]);

  // Update params by updating the URL search string
  const updateParams = useCallback(
    (updatedValues: Partial<PaginatedListParams>) => {
      setSearchParams(previousSearchParams => {
        let updatedFilters;
        if (!updatedValues.filters) {
          // If filters are not updated, keep the existing filters
          updatedFilters = params.filters;
        } else if (Object.keys(updatedValues.filters).length) {
          // If filters are updated, merge the existing filters with the updated filters
          updatedFilters = { ...params.filters, ...updatedValues.filters };
        } else {
          // If filters are set to an empty object, clear the filters
          updatedFilters = {};
        }

        return serializeParams(
          { ...params, ...updatedValues, filters: updatedFilters },
          config,
          previousSearchParams.toString()
        );
      });
    },
    [params]
  );

  // Initialize params when the component mounts
  useEffect(() => {
    if (Object.keys(initialParams).length) {
      updateParams(initialParams);
    }
    setIsInitialized(true);
  }, []);

  const setPage = useCallback((page: number) => updateParams({ page }), [updateParams]);

  const setPerPage = useCallback(
    (pageSize: number) => updateParams({ perPage: pageSize, page: 1 }),
    [updateParams]
  );

  const setSearch = useCallback(
    (search: string) => updateParams({ search, page: 1 }),
    [updateParams]
  );

  const setSort = useCallback(
    (sortBy: PaginatedListParams['sortBy'], sortDirection: PaginatedListParams['sortDirection']) =>
      updateParams({ sortBy, sortDirection }),
    [updateParams]
  );

  const setFilter = useCallback(
    (key: FilterKey, value: FilterValue) => updateParams({ filters: { [key]: value }, page: 1 }),
    [updateParams]
  );

  const clearFilters = useCallback(() => updateParams({ filters: {}, page: 1 }), [updateParams]);

  return {
    params,
    config,
    setPage,
    setPerPage,
    setSearch,
    setSort,
    setFilter,
    clearFilters,
  };
};

const serializeParams = (
  params: PaginatedListParams,
  config: PaginatedListConfig,
  // Existing search string to merge with new params
  initialSearchString = ''
): string => {
  const queryParams = {
    page: params.page,
    per_page: params.perPage,
    sort: params.sortBy,
    direction: params.sortDirection,
    [config.searchKey || 'search']: params.search || undefined,
    ...params.filters,
  };

  const getParamKey = (key: string) =>
    config.urlStatePrefix ? `${config.urlStatePrefix}.${key}` : key;

  const searchParams = new URLSearchParams(initialSearchString);

  for (const [key, value] of Object.entries(queryParams)) {
    if (value !== undefined) {
      searchParams.set(getParamKey(key), String(value));
    } else {
      searchParams.delete(getParamKey(key));
    }
  }

  return searchParams.toString();
};

const parseParams = <FilterKey extends string>(
  urlSearchString: string,
  config: PaginatedListConfig<FilterKey>
): Partial<PaginatedListParams<FilterKey>> => {
  const searchParams = new URLSearchParams(urlSearchString);

  const getParam = (key: string) =>
    config.urlStatePrefix
      ? searchParams.get(`${config.urlStatePrefix}.${key}`)
      : searchParams.get(key);

  const parsedFilters = Object.fromEntries(
    config.filterKeys.map(key => [key, getParam(key)]).filter(([, value]) => value)
  );

  const hasAppliedFilters = !!Object.keys(parsedFilters).length;

  const allParsedParams = {
    page: Number(getParam('page')) || undefined,
    perPage: Number(getParam('per_page')) || undefined,
    search: getParam(config.searchKey) || undefined,
    sortBy: getParam('sort') || undefined,
    sortDirection: (getParam('direction') ||
      undefined) as PaginatedListParams<FilterKey>['sortDirection'],
    filters: hasAppliedFilters ? parsedFilters : undefined,
  };

  // Remove undefined values
  const filteredParsedParams = Object.fromEntries(
    Object.entries(allParsedParams).filter(([, value]) => value !== undefined)
  );

  return filteredParsedParams;
};

/**
 * Serialize params for use in backend API query
 */
export const serializeParamsForQuery = (state: PaginatedListState): string => {
  // When serializing params for API query, we don't want to include the URL prefix
  const configWithDisabledURLPrefix = { ...state.config, urlStatePrefix: undefined };

  return serializeParams(state.params, configWithDisabledURLPrefix);
};
