function paginate<T>(array: Array<T>, page_size: number, page_number: number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export function search<T extends object>(array: Array<T>, search: string) {
  const searchTerms = search.toLowerCase().split(' ');

  return array.filter(item => {
    return searchTerms.every(term => {
      return Object.values(item).some(
        value => typeof value === 'string' && value.toLowerCase().includes(term)
      );
    });
  });
}

const getPerPageKey = (perPageOptions: readonly number[]) =>
  `rocket:tablePerPage:${perPageOptions}`;

// TODO remove default parameter when we remove old design pages
export function loadDefaultPerPage(perPageOptions: readonly number[] = []) {
  const savedValue = window.localStorage.getItem(getPerPageKey(perPageOptions));
  if (savedValue) {
    return Number(savedValue);
  }
  return perPageOptions[0] || 10;
}

export function saveDefaultPerPage(perPageOptions: readonly number[], value: number) {
  try {
    window.localStorage.setItem(getPerPageKey(perPageOptions), String(value));
  } catch (e) {
    // error
  }
}

export default paginate;
